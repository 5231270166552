const handle = "derrickmehaffy";

const links = [
  {
    label: "Github",
    url: `https://github.com/${handle}`,
  },
  {
    label: "Twitter",
    url: `https://twitter.com/${handle}`,
  },
  {
    label: "Youtube",
    url: `https://www.youtube.com/${handle}`,
  },
];

export default links;
