import { Github } from "@styled-icons/boxicons-logos/Github";
import { Twitter } from "@styled-icons/boxicons-logos/Twitter";
import { Youtube } from "@styled-icons/boxicons-logos/Youtube";

const Icons = {
  Github,
  Twitter,
  Youtube,
};

export default Icons;
